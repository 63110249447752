export enum JDROID_CURRENT_SIZE {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE'
}

export enum TAB_BUTTON_POSITION {
  MAXIMIZE = 'Maximize',
  CENTER = 'Center'
}

export enum TAB_ICONS_NAMES {
  CODE_EDITOR = 'Code Editor',
  EXTERNAL_LIBRARIES = 'External Libraries',
  UPLOAD_FILES = 'Upload Files',
  IO = 'I/O',
  JDROID = 'JDroid',
  BROWSER = 'Browser',
  DB_TERMINAL = 'DB_TERMINAL',
  API_TAB = 'API_TAB',
  TERMINAL = 'TERMINAL',
  CONSOLE = 'CONSOLE',
  RESOURCES = 'RESOURCES'
}

export const IDETABSVIEW = {
  IDE: 'ideview',
  ADVANCEIDE: 'advanceide',
  JDROID: 'jdroid',
  JDROIDCHAT: 'jdroidchat',
  HTML: 'htmlview',
  DB_TERMINAL: 'db_terminals'
}

export const IDESCROLLTO = {
  OUTPUT: 'scrollOutputComp'
}

export const IDEVIEWTABS = {
  CODE_EDITOR: 'tabs-code-editor',
  INPUTFILES: 'tabs-input-files',
  LIBRARIES: 'tabs-libs',
  INPUT: 'tabs-input',
  OUTPUT: 'tabs-output',
  IO: 'tabs-io',
  JDROID: 'tabs-jdroid',
  BROWSER: 'tabs-browser',
  DB_TERMINAL: 'tabs-dbterminal',
  API_TAB: 'tabs-api',
  TERMINAL: 'tabs-terminal',
  CONSOLE: 'tabs-console',
  RESOURCES_TAB: 'RESOURCES_TAB'
}

export const IDEVIEWTABS_MOBILE = {
  JDROID: 'tabs-jdroid-mobile',
  IDE: 'tabs-ide-mobile',
  INPUTFILES: 'tabs-input-files-mobile',
  LIBRARIES: 'tabs-libs-mobile',
  INPUT: 'tabs-input-mobile',
  OUTPUT: 'tabs-output-mobile',
  SOCIALSHARE: 'tabs-social-mobile',
  OUTPUTHTML: 'tabs-output-mobile-html',
  DB_TERMINAL: 'tabs-db-terminal-mobile',
  APITAB: 'tabs-api-browser-mobile',
  TERMINAL: 'tabs-terminal-mobile',
  CONSOLE: 'tabs-console-mobile',
  RESOURCETAB: 'tabs-resource-mobile'
}

export const IDEASSESSMENTTABS = {
  TRYSECTION: 'trysection-tabs'
}

//Model
//user profile
export const USERPROFILETABS = {
  BASIC: 'tabs-basic',
  PASSWORD: 'tabs-password'
}

// Pricing tabs
export enum PRICING_TAB_ENUM {
  INDIVIDUAL = 'Individual Plan',
  ORGANIZATION = 'Organisation Plan'
}

export const HTML_TABS = [TAB_ICONS_NAMES.BROWSER, TAB_ICONS_NAMES.RESOURCES]

export const ADVANCE_COMPILER_TABS = [
  TAB_ICONS_NAMES.BROWSER,
  TAB_ICONS_NAMES.API_TAB,
  TAB_ICONS_NAMES.TERMINAL,
  TAB_ICONS_NAMES.CONSOLE,
  TAB_ICONS_NAMES.RESOURCES
]
export const ADVANCE_COMPILER_DATABASE_TABS = [
  TAB_ICONS_NAMES.DB_TERMINAL,
  TAB_ICONS_NAMES.API_TAB,
  TAB_ICONS_NAMES.RESOURCES
]

export const NON_ADVANCE_COMPILER_TABS = [
  TAB_ICONS_NAMES.IO,
  TAB_ICONS_NAMES.EXTERNAL_LIBRARIES,
  TAB_ICONS_NAMES.UPLOAD_FILES,
  TAB_ICONS_NAMES.API_TAB,
  TAB_ICONS_NAMES.RESOURCES
]

export enum TABS_WIDTH {
  LARGE = 400,
  MEDIUM = 300,
  SMALL = 200
}

export enum TABS_WIDTH_TYPE {
  LARGE = 'large',
  MEDIUM = 'medium',
  SMALL = 'small'
}

export enum SWITCHTAB_POSITION {
  NEXT = 'Next',
  PREVIOUS = 'Previous'
}
